@import url('https://fonts.cdnfonts.com/css/poppins');
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Scrollbar */
::-webkit-scrollbar {
    width: 5px;
    height: 2px;
}

/* Track */

::-webkit-scrollbar-track {
    
    border-radius: 100px;
    height: 10px;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background-color: #6C3D9E;
    border-radius: 10px;
    width: 30px;
    opacity: 0;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: #777;
    opacity: 0;
}

.swal2-popup {
  font-size: 12px !important;
  font-weight: 700;
  font-family: 'Poppins';
}

.phone-input .form-control {
  width: 100%;
}